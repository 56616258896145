export default {
    fixed_asset: [{
        _id: 'realestate',
        displayName: 'Real Estate',
    }, {
        _id: 'vehicles',
        displayName: 'Vehicles',
    }],
    current_asset: [{
        _id: 'Cash',
        displayName: 'Cash',
    }, {
        _id: 'Bank',
        displayName: 'Bank',
    }, {
        _id: 'Bank1',
        displayName: 'Bank1',
    }, {
        _id: 'Bank2',
        displayName: 'Bank2',
    }],
    current_liability: [],
    long_term_liability: [{
        _id: 'loan',
        displayName: 'Loan',
    }],
    capital: [{
        _id: 'capital',
        displayName: 'Capital',
    }],
    other_income: [{
        _id: 'advertisement',
        displayName: 'Advertisement Income',
    }],
    other_expence: [{
        _id: 'rent',
        displayName: 'Rent',
    }, {
        _id: 'bill',
        displayName: 'Bill',
    }, {
        _id: 'stationary',
        displayName: 'Stationary Items',
    }, {
        _id: 'donation',
        displayName: 'Donation',
    }, {
        _id: 'daily_expences',
        displayName: 'Daily Expences',
    }, {
        _id: 'staff_payment',
        displayName: 'Staff Payment',
    }, {
        _id: 'maintenance',
        displayName: 'Shop maintenance',
    }],
    tax_paid: [{
        _id: 'income_tax',
        displayName: 'Income Tax',
    },
    {
        _id: 'cgst',
        displayName: 'CGST',
    },
    {
        _id: 'sgst',
        displayName: 'SGST',
    }],
    asset: [{
        _id: 'accounts_receivable',
        displayName: 'Acconts Receivale',
    }],
    liability: [{
        _id: 'accounts_payable',
        displayName: 'Accounts Payable',
    }],
};

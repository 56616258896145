module.exports = {
    'transaction': {
        'transaction_rule': {
            'item_fields': {
                'pack_qty': {
                    'a': 'Qty',
                    'b': 10,
                    'c': 1,
                    'd': false,
                    'e': 10,
                    'f': false,
                    'g': 0
                },
                'free_qty': {
                    'a': 'Free',
                    'b': 12,
                    'c': 1,
                    'd': false,
                    'e': 12,
                    'f': false,
                    'g': 0
                },
                'lot_amt': {
                    'a': 'Lot Amt',
                    'b': 0,
                    'c': 1,
                    'e': 0,
                    'd': '+',
                    'f':  ['*', 'free_qty', 'rate']
                },
                'pack': {
                    'a': 'PCS/ Pack',
                    'b': 15,
                    'c': 1,
                    'd': false,
                    'e': 0,
                    'f': false,
                    'g': 1
                },
                'unit': {
                    'a': 'Unit',
                    'b': 0,
                    'c': 2,
                    'd': false,
                    'e': 0,
                    'f': false,
                    'g': 0
                },
                'mrp': {
                    'a': 'MRP',
                    'b': 20,
                    'c': 1,
                    'd': false,
                    'e': 20,
                    'f': false,
                    'g': 0
                },
                'mrp_per_pcs': {
                    'a': 'MRP/ PCS',
                    'b': 0,
                    'c': 0,
                    'd': false,
                    'e': 0,
                    'f': ['/', 'mrp', 'pack']
                },
                'ptr': {
                    'a': 'PTR',
                    'b': 25,
                    'c': 1,
                    'd': false,
                    'e': 25,
                    'f': false,
                    'g': 0,
                    'h': -1
                },
                'rate': {
                    'a': 'Rate',
                    'b': 35,
                    'c': 1,
                    'd': false,
                    'e': 35,
                    'f': false,
                    'g': 0
                },
                'rate_per_pcs': {
                    'a': 'Rate/ PCS',
                    'b': 0,
                    'c': 0,
                    'd': false,
                    'e': 0,
                    'f': ['/', 'rate', 'pack']
                },
                'total_amt':  {
                    'a': 'Total',
                    'b': 45,
                    'c': 0,
                    'e': 0,
                    'd': '+',
                    'f': ['*', 'pack_qty',  'rate']
                },
                'discount_per': {
                    'a': 'Disc (%)',
                    'b': 50,
                    'c': 1,
                    'e': 50,
                    'd': '+',
                    'f': false,
                    'g': 0
                },
                'discount_amt': {
                    'a': 'Disc.',
                    'b': 0,
                    'c': 0,
                    'e': 0,
                    'd': '+',
                    'f': ['/', ['*', 'discount_per', ['*',  'pack_qty', 'rate']], 100]
                },
                'taxable_value':  {
                    'a': 'Taxable',
                    'b': 60,
                    'c': 0,
                    'e': 60,
                    'd': '+',
                    'f': ['-', ['*', 'pack_qty',  'rate'], 'discount_amt']
                },
                'cgst': {
                    'a': 'CGST (%)',
                    'b': 65,
                    'c': 1,
                    'd': false,
                    'e': 65,
                    'f': false,
                    'g': 0
                },
                'cgst_amount': {
                    'a': 'CGST',
                    'b': 0,
                    'c': 0,
                    'd': '+',
                    'e': 0,
                    'f': ['/', ['*', 'cgst', 'taxable_value'], 100]
                },
                'sgst': {
                    'a': 'SGST (%)',
                    'b': 75,
                    'c': 1,
                    'd': false,
                    'e': 75,
                    'f': false,
                    'g': 0
                },
                'sgst_amount': {
                    'a': 'SGST',
                    'b': 0,
                    'c': 0,
                    'd': '+',
                    'e': 0,
                    'f': ['/', ['*', 'sgst', 'taxable_value'], 100]
                },
                'igst': {
                    'a': 'IGST (%)',
                    'b': 80,
                    'c': 1,
                    'd': false,
                    'e': 80,
                    'f': false,
                    'g': 0
                },
                'igst_amount': {
                    'a': 'IGST',
                    'b': 0,
                    'c': 0,
                    'd': '+',
                    'e': 0,
                    'f': ['/', ['*', 'igst', 'taxable_value'], 100]
                }
            },
            'price_formula': ['+',  ['+', 'taxable_value', ['+', 'cgst_amount', 'sgst_amount']], 'igst_amount'],
            'quantity_formula':['*',  'pack', ['+', 'free_qty', 'pack_qty']],
            'header_fields': {
                'invoice_title': {
                    'a': 'Tax Invoice',
                    'b':0,
                    'c': 1,
                    'd': false,
                    'e': 0,
                    'f': false,
                    'g': 'CASH/CREDIT MEMO'
                }
            },
            'footer_fields': {
                'ag_total_amt': {
                    'a': 'Total',
                    'b': 10,
                    'c': 0,
                    'd': false,
                    'e': 10,
                    'f': false,
                    'g': 0
                },
                'ag_discount_amt': {
                    'a': 'Disc Amt',
                    'b': 20,
                    'c': 0,
                    'd': false,
                    'e': 20,
                    'f': false,
                    'g': 0
                },
                'ag_taxable_value': {
                    'a': 'Taxable Value',
                    'b': 30,
                    'c': 0,
                    'd': false,
                    'e': 30,
                    'f': false,
                    'g': 0
                },
                'ag_cgst_amount': {
                    'a': 'CGST Value',
                    'b': 40,
                    'c': 0,
                    'd': false,
                    'e': 40,
                    'f': false,
                    'g': 0
                },
                'ag_sgst_amount': {
                    'a': 'SGST Value',
                    'b': 45,
                    'c': 0,
                    'd': false,
                    'e': 45,
                    'f': false,
                    'g': 0
                },
                'ag_igst_amount': {
                    'a': 'IGST Value',
                    'b': 50,
                    'c': 0,
                    'd': false,
                    'e': 50,
                    'f': false,
                    'g': 0
                },
                'ag_lot_amt': {
                    'a': 'LOT Amt',
                    'b':50,
                    'c': 0,
                    'd': false,
                    'e': 45,
                    'f': false,
                    'g': 0
                },
                'discountper': {
                    'a': 'Discount(%)',
                    'b': 55,
                    'c': 1,
                    'd': false,
                    'e': 55,
                    'f': false,
                    'g': 0
                },
                'discount': {
                    'a': 'Disc Value',
                    'b': 58,
                    'c': 0,
                    'd': false,
                    'e': 58,
                    'f': ['/',  ['*', 'discountper', 'ag_total'], 100],
                    'g': 0
                },
                'flatdiscount': {
                    'a': 'Flat Discount',
                    'b': 60,
                    'c': 1,
                    'd': false,
                    'e': 60,
                    'f': false,
                    'g': 0
                },
                'cr_note': {
                    'a': 'Cr Note Amt',
                    'b': 61,
                    'c': 0,
                    'd': false,
                    'e': 61,
                    'f': false,
                    'g': 0
                },
                'adj': {
                    'a': 'Round off',
                    'b': 65,
                    'c': 0,
                    'd': false,
                    'e': 65,
                    'f': ['-', ['int', 'tot_noroundoff', 0], 'tot_noroundoff'],
                    'g': 'Round off'
                },
                'tot_noroundoff': {
                    'a': 'tot w/o roundoff',
                    'b': 0,
                    'c': 0,
                    'd': false,
                    'e': 0,
                    'f': ['-', ['-',
                            ['-', 'ag_total', ['/',  ['*', 'discountper', 'ag_total'], 100]],
                            'flatdiscount'], 'cr_note'],
                    'g': 0
                },
            },
            'total_price_formula': ['+', 'tot_noroundoff', 'adj'],
            'commission_ammount_formula': false
        }

    },
    'seller': {

    },
    'payment': {

    }
};

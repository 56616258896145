export default {
    fixed_asset_purchase: {
        displayName: 'Fixed Asset Purchase',
        description: 'Purchase of shop, vechicle, furniture etc.',
        dr_account_type: 'fixed_asset',
        cr_account_type: 'current_asset',
    },
    fixed_asset_sale: {
        displayName: 'Fixed Asset Sale',
        description: 'Sale of shop, vechicle, furniture etc.',
        dr_account_type: 'current_asset',
        cr_account_type: 'fixed_asset',
    },
    current_asset_transfer: {
        displayName: 'Deposit/Withdrawl',
        description: 'Deposit to business account in bank and vice versa',
        dr_account_type: 'current_asset',
        cr_account_type: 'current_asset',
    },
    owners_drawings: {
        displayName: 'Owners Drawings',
        description: 'Amount drawn from business to personal account',
        dr_account_type: 'capital',
        cr_account_type: 'current_asset',
    },
    owners_investment: {
        displayName: 'Owners Investment',
        description: 'New investment for the business from personal savings',
        dr_account_type: 'current_asset',
        cr_account_type: 'capital',
    },
    other_expence: {
        displayName: 'Other Expences',
        description: 'Purchase of stationary items, rent and bill payments, tea/coffee etc',
        dr_account_type: 'other_expence',
        cr_account_type: 'current_asset',
    },
    other_income: {
        displayName: 'Other Incomes',
        description: 'Any income other than sales, e.g. advertisement',
        dr_account_type: 'current_asset',
        cr_account_type: 'other_income',
    },
    loan_receive: {
        displayName: 'Loan Receive',
        description: 'New loan from bank for business',
        dr_account_type: 'current_asset',
        cr_account_type: 'long_term_liability',
    },
    loan_payment: {
        displayName: 'Loan Payment',
        description: 'Pricipal amount of liability paid',
        dr_account_type: 'long_term_liability',
        cr_account_type: 'current_asset',
    },
    tax_payment: {
        displayName: 'Tax Payment',
        description: 'Tax paid to govt.',
        dr_account_type: 'tax_paid',
        cr_account_type: 'current_asset',
    },
};
